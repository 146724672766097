<template>
  <div class="container page">
    <van-nav-bar :title="$t('withdraw.with_center')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <template #right>
        <span class="nav-right" @click="$router.push({ path: '/WithdrawRecord' })">{{ $t("withdraw.with_record") }}</span>
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span>{{ $t("withdraw.with_money") }} ({{ $t("reservation.unit") }})</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType"></span>
            <van-field v-model="withdraw_money" type="number" />
          </div>
          <span class="all" @click="allMoeny()">{{ $t("index.all") }}</span>
        </div>
        <div class="information">
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px;">
                <p>
                  1.{{ $t("withdraw.single_limit") }}{{ $t("withdraw.low") }} {{ this.userInfo.min }} {{
                    $t("withdraw.heigh") }} {{ this.userInfo.max }} {{ $t("reservation.unit") }}
                </p>
                <p>2.{{ $t("withdraw.with_num") }}{{ this.userInfo.num }}{{ $t("withdraw.number") }}</p>
                <p>3.{{ $t("withdraw.with_tip") }}</p>
              </div>
              <template #reference @click="withdrawInfo()">
                <van-icon name="info-o" />
                {{ $t("withdraw.limit_desc") }}
              </template>
            </van-popover>
          </div>
          <div class="balance">
            <span>{{ $t("my.balance") }}：</span>
            <span class="number">{{ ynmony | numyn }}{{ $t("reservation.unit") }}</span>
          </div>
        </div>
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()">
        {{ $t("withdraw.immediately_withdraw") }}</van-button>
      <!-- 支付密码弹窗 -->
      <van-popup v-model="popShow" style="width: 90%;top:40%" :close-on-click-overlay="false">
        <div class="pay-title">Silakan masukkan kata sandi pembayaran</div>
        <van-password-input :value="opw" :length="4" :gutter="10" :focused="showKeyboard" @focus="showKeyboard = true" />
        <div class="forget-password" @click="forgetFn()">Tidak ingat kata sandi?</div>
        <div class="btn-box">
          <span @click="cancelBtn()">Membatalkan</span>
          <span @click="sureBtn()">Tentu</span>
        </div>
      </van-popup>
      <!-- 数字键盘 -->
      <van-number-keyboard v-model="opw" style="z-index: 99999;" :show="showKeyboard" @blur="showKeyboard = false" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money: "",
      ynmony: 0.000,
      userInfo: {},
      withdrawRole: {},
      popShow: false,
      opw: '',
      showKeyboard: false,
      btnShow: true
    };
  },
  mounted(){
    setInterval(()=>{
      if(localStorage.getItem('token')){
        this.getUserInfo()
      }
    },5000)
  },
  methods: {
    cancelBtn() {
      this.popShow = false;
      this.opw = '';
      this.showKeyboard = false;
    },
    forgetFn() {
      this.$toast('Silakan hubungi departemen layanan pelanggan khusus');
    },
    back() {
      return window.history.back();
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          console.log(res.data)
          this.ynmony = new Number(this.userInfo.money);
          this.name = res.data.name;
          if(res.data.status==0){
            localStorage.clear();
            this.$router.push({path:'/Login'})
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getUserWithdrawRole() {
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res => {
        if (res.code === 200) {
          console.log(res.data)
          this.withdrawRole = res.data;
          this.ynmony = new Number(this.userInfo.money);
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    allMoeny() {
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw() {
      // 最少/最多
      if (this.withdraw_money>this.userInfo.max || this.withdraw_money<this.userInfo.min || this.withdraw_money<=0) {
        this.$toast(`Jumlah penarikan tidak boleh lebih besar daril ${this.userInfo.max}`);
        return false;
      } else {
        this.popShow = true;
        this.showKeyboard = true;
      }
    },
    sureBtn() {
      if (this.opw.length == 4) {
        if (this.btnShow) {
          this.btnShow = false;
          this.$http({
            method: 'post',
            data: {
              money: this.withdraw_money,
              paypassword: this.opw
            },
            url: 'user_set_withdraw'
          }).then(res => {
            if (res.code === 200) {
              location.reload()
              this.$toast(res.msg);
              this.getUserInfo();
              this.getUserWithdrawRole();
            } else if (res.code === 401) {
              this.$toast(res.msg);
            }
            setTimeout(() => {
              this.btnShow = true;
            }, 500)
          })
        } else {
          setTimeout(() => {
            this.btnShow = true;
          }, 5000)
        }
      } else {
        this.$toast("Silakan masukkan kata sandi pembayaran yang benar!");
      }
    },
    withdrawInfo() {
      this.showPopover = true;
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo();
      this.getUserWithdrawRole();
    }
  },
  filters: {
    numyn: (val) => {
      val = '' + val // 转换成字符串
      let int = val
      int = int.split('').reverse().join('') // 翻转整数
      let temp = '' // 临时变量
      for (let i = 0; i < int.length; i++) {
        temp += int[i]
        if ((i + 1) % 3 === 0 && i !== int.length - 1) {
          temp += '.' // 每隔三个数字拼接一个逗号
        }
      }
      temp = temp.split('').reverse().join('') // 加完逗号之后翻转
      return temp // 返回
    }

  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}

.container .main {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}

.container .main .withdrawMoney {
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #fff;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .money {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}

.container .main .withdrawMoney .money .moneyNumber {
  font-size: 50px;
  display: flex;
  flex-direction: row;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .money .all {
  color: #d10404;
}

.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0 !important;
}

.container .main .withdrawMoney .information {
  padding-bottom: 30px;
}

.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}

.container .main .withdrawMoney span {
  padding: 10px 0;
}

.container .main .withdrawMoney .information .balance .number {
  color: #d10404;
}

.withdraw_btn {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(90deg,  #fbbebe, #f698ff);
}

.container .wrapper {
  height: 100%;
  background-color: #fff;
}

.container .wrapper .item .title {
  margin: 40px 0;
  line-height: 20px;
  font-size: 30px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

::v-deep .van-password-input {
  width: 90%;
  height: 150px;
  margin: 0 auto;

}

::v-deep .van-password-input__security li {
  font-size: 30px;
  line-height: 30;
  background-color: #ebedf0;
}

::v-deep .van-password-input__security {
  height: 130px;
}

::v-deep .van-password-input .van-password-input__security .van-password-input__item {
  height: 100%;
  text-align: center;
  border-radius: 30px;
  border: none;
}

.van-password-input__security i {
  width: 25px;
  height: 25px;
}

.van-field__label {
  color: #1b74ce;
}

::v-deep .van-key {
  height: 100px;
  font-size: 55px;
  line-height: 20px;
  border-radius: 20px;
}

::v-deep .van-number-keyboard {
  z-index: 100;
  width: 100%;
  padding-bottom: 30px;
  background-color: #f2f3f5;
}

::v-deep .van-key__collapse-icon {
  width: 50px;
  height: 50px;
}

::v-deep .van-key__delete-icon {
  width: 50px;
  height: 50px;
}

.container .wrapper .sub-btn {
  margin: 110px 0 0 10%;
  height: 100px;
  width: 80%;
  font-size: 35px;
  border-radius: 50px;
  color: #fff;
  background: linear-gradient(270deg,  #fbbebe, #f698ff);
}

.van-popup--center {
  border-radius: 20px;
}

.pay-title {
  width: 100%;
  height: 120px;
  text-align: center;
  line-height: 120px;
  font-size: 4.5vw;
}

.forget-password {
  width: 200px;
  height: 30px;
  float: right;
  font-size: 20px;
  margin-right: 26px;
  color: #636363;
}

.btn-box {
  width: 100%;
  float: left;
  margin-top: 20px;
  border-top: 1px solid #eee;
}

.btn-box span {
  width: 49.8%;
  height: 100%;
  float: left;
  text-align: center;
  line-height: 110px;
  color: #000;
}

.btn-box span:nth-of-type(1) {
  border-right: 1px solid #eee;
  color: #666;
}
</style>
